export const REACH_DATA = [
  {
    name: 'logiplaydays',
    id_marca: 17,
    id_subcate: null,
    ids_productos: [
      10914, 13882, 13879, 13881, 15642, 15637, 14468, 15638, 15949, 15950, 15632, 14460, 15644,
      12389, 13802, 12390, 9156, 10216, 11085, 11086, 11197, 11200, 15665, 15689, 14461, 13696,
      12772, 15654, 15655, 13610, 14876, 11695, 10729, 9558, 9548, 14355, 14642, 14356, 15650, 9734,
      15945, 14641, 15643, 15645, 10416, 9365, 15690, 11595, 10157, 13745, 15633, 15634, 15662,
      15663, 15664, 11027, 10078, 10255,
    ],
  },
  {
    name: 'amdwarun',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      15525, 15527, 15528, 15371, 15373, 15559, 15561, 15562, 15987, 15986, 15563, 15989, 14359,
      14376, 14065, 14381, 14489, 14383, 14084, 14379, 14377, 14085, 14378, 14662, 15988, 15565,
      16100, 16038, 16037,
    ],
  },
  {
    name: 'nvidianotevideo',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      14649, 14718, 14773, 14779, 14808, 15156, 15260, 15261, 15535, 15552, 15993, 15310, 15587,
      15728, 15893, 15902, 16045, 16048, 14772, 14778, 14806, 15262, 15603, 15781, 15596, 15699,
      15780, 15795, 16041, 16043, 16049, 14771, 14777, 14804, 14809, 15263, 15585, 15306, 15602,
      15776, 15778, 15779, 15828, 16050, 14155, 14156, 14776, 14810, 15311, 15372, 16042, 16044,
      15720, 15775, 15826,
    ],
  },
  {
    name: 'cyberliveshop',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      16269, 16270, 15750, 15751, 11241, 15788, 15791, 15793, 15831, 6274, 16212, 16211, 16210,
      15877, 15879, 15882, 11111, 16585,
    ],
  },
  {
    name: 'nvidiaindianajones',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      16680, 16628, 16627, 16626, 16585, 16477, 16354, 16350, 16349, 16282, 16169, 16166, 16165,
      16163, 16162, 16153, 16152, 16111, 16110, 16109, 16050, 16049, 16048, 16045, 16044, 16043,
      16042, 16041, 16032, 15993, 15970, 15955, 15902, 15893, 15832, 15828, 15826, 15795, 15781,
      15780, 15779, 15778, 15776, 15775, 15728, 15720, 15699, 15603, 15602, 15596, 15587, 15585,
      15552, 15535, 15372, 15311, 15310, 15309, 15306, 15263, 15262, 15261, 15260, 15156, 14810,
      14809, 14808, 14806, 14804, 14779, 14778, 14777, 14776, 14773, 14772, 14771, 14718, 14649,
      14156, 14155,
    ],
  },
  {
    name: 'AMDJugamos',
    id_marca: null,
    id_subcate: null,
    ids_productos: [16708, 15304, 15555, 16117, 16024, 14723, 14722],
  },
  {
    name: 'nvidiavideo',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      12430, 12745, 12778, 12886, 12887, 12920, 12921, 12925, 12959, 13076, 13082, 13084, 13085,
      13102, 13103, 13104, 13111, 13112, 13113, 13114, 13166, 13300, 13303, 13305, 13306, 13426,
      13474, 13540, 13558, 13666, 13667, 13712, 13904, 13929, 14014, 14047, 14236, 14336, 14338,
      14339, 14340, 14342, 14343, 14344, 14346, 14347, 14361, 14362, 14363, 14370, 14403, 14655,
      14656, 14919, 14949, 14950, 14951, 15062, 15063, 15065, 15066, 15153, 15154, 15677, 15678,
      15679, 15680, 15718, 15719, 15720, 15749, 15767, 15768, 15769, 15773, 15774, 15775, 15826,
      15832, 15864, 15865, 15911, 15916, 15955, 15956, 15959, 15961, 15970, 16028, 16029, 16032,
      16033, 16034, 16052, 16119, 16205, 16215, 16254, 16282, 16290, 16340, 16714, 13092, 13552,
      13630, 13631, 13649, 13689, 13783, 14001, 14717, 14946, 15293, 15294, 15551, 15568, 15686,
      15727, 15770, 15827, 15971, 11718, 11719, 11728, 11850, 11851, 11865, 11871, 11872, 11876,
      11884, 11914, 11936, 11942, 11943, 11964, 11966, 12039, 12137, 12138, 12286, 12385, 12620,
      12631, 12649, 12679, 12793, 12858, 13060, 13480, 13567, 13628, 13648, 13688, 14520, 14768,
      15868, 16222, 11308, 11310, 11316, 11353, 11354, 11355, 11398, 11432, 11609, 11615, 11618,
      11619, 11620, 12210, 12382, 12448, 12468, 12481, 12515, 12575, 12606, 12698, 12739, 12780,
      13483, 13627, 13690, 13918, 14002, 11023, 11024, 11071, 11171, 11186, 11203, 11307, 11311,
      11356, 11610, 11623, 11624, 11744, 11753, 11917, 11937, 11963, 12216, 12249, 12345, 12358,
      12410, 12455, 12467, 12483, 12525, 12526, 12619, 12624, 13197, 13198, 13216, 13481, 13625,
      13626, 14003, 14004, 11189, 12218, 12378, 12484, 12559, 12574, 12625, 12678, 12974, 12976,
      13063, 13372, 13566, 10707, 10722, 10783, 10840, 10937, 11037, 11188, 11269, 11270, 11271,
      11357, 11538, 11866, 11874, 11967, 12134, 12219, 12267, 12381, 12391, 12527, 12550, 12555,
      12640, 12971, 13065, 13217, 13553, 13554, 13578, 13624, 13650, 13687, 13782, 13971, 1430,
    ],
  },
  {
    name: 'intelAssassinsCivilization',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      16970, 16838, 16837, 16836, 16835, 16258, 16115, 15252, 15251, 15238, 15237, 15235, 15234,
    ],
  },
  {
    name: 'asustufnotebooks',
    id_marca: null,
    id_subcate: null,
    ids_productos: [16779, 16780, 16781],
  },
  {
    name: 'amdMonsterHunterWilds',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      16660, 16440, 16348, 16347, 16251, 16168, 16167, 15989, 15988, 15987, 15986, 15566, 15565,
      15564, 15563, 15562, 15528, 15527, 15525, 15373, 15371, 14725, 14724, 14669, 16100, 16099,
      16038, 16037, 14663, 14662, 14381, 14379, 14378, 14377, 14376, 14085, 14084, 14065,
    ],
  },
  {
    name: 'seccionNvidia',
    id_marca: null,
    id_subcate: null,
    ids_productos: [
      15254, 15255, 15257, 15258, 15585, 15596, 15892, 16582, 15627, 16033, 16164, 16537, 16781,
      16883, 16988, 16866,
    ],
  },
  {
    name: 'preciosBombaNotebooks',
    id_marca: null,
    id_subcate: null,
    ids_productos: [16051, 16941, 16850, 17082],
  },
  {
    name: 'preciosBombaMonitores',
    id_marca: null,
    id_subcate: null,
    ids_productos: [16243, 16240, 16213],
  },
  {
    name: 'seccionPreciosBomba',
    id_marca: null,
    id_subcate: null,
    ids_productos: [16243, 16240, 16213, 16051, 16941, 16850, 17082],
  },
];
