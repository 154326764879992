import { Product } from '@core/models/piramide/Product';

export class ReachOut {
  public idMarca: number;
  public idSubcate: number;
  public idCate: number;
  public idsProducts: number[];
  public discountPercent: number;
  public name: string;
  constructor(data: any) {
    this.name = data.name;
    this.idMarca = data.id_marca;
    this.idSubcate = data.id_subcate;
    this.idsProducts = data.ids_productos;
  }

  public filterProductsByReach(products: Product[]): Product[] {
    const filteredProducts = products.filter((p) => {
      if (this.idsProducts.length) {
        return this.idsProducts.includes(p.id_producto);
      }
      if (this.idMarca) {
        return this.idMarca == p.id_marca;
      }
      if (this.idSubcate) {
        return this.idSubcate == p.id_subcategoria;
      }
      if (this.idCate) {
        return this.idCate == p.id_categoria;
      }
    });
    return filteredProducts;
  }
}
