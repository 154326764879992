import { Injectable } from '@angular/core';
import { ReachOut } from '@core/classes/reachOut';
import { REACH_DATA } from '@core/utils/reachData';
import { first, of } from 'rxjs';
import { NavigationService } from './navigation.service';
import { Product } from '@core/models/piramide/Product';

@Injectable({
  providedIn: 'root',
})
export class ReachOutService {
  private loadedReaches: ReachOut[] = [];
  constructor(private navigationService: NavigationService) {}

  public getReachByName(name: string): ReachOut {
    return this.loadedReaches.find((r) => r.name == name);
  }

  public getAllReaches(): void {
    of(REACH_DATA)
      .pipe(first())
      .subscribe((res) => {
        this.loadedReaches = res.map((r) => new ReachOut(r));
      });
  }

  public deleteProductListReach(): void {
    this.navigationService.navigateUrlWithPersistence(['/productos'], {
      queryParams: {
        alcance: null,
      },
    });
  }

  public productFromReach(reachName: string, products: Product[]): Product[] {
    const selectedReach = REACH_DATA.find((r) => r.name === reachName);
    if (selectedReach)
      return products.filter((p) => selectedReach.ids_productos.includes(p.id_producto));
    return [];
  }
}
